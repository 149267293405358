#media-player {
    display: flex;

    .media-player-background {
        background: url(../resources/bgm/jukebox-bg.png)
    }
    .media-player-title {
        background-color: #ED3636;
        position: relative;
        height: 34px;
        width: 502px;
        margin-left: 10px;
        display: flex;

        .media-title {
            overflow: hidden;
            width: 450px;
            margin-left: 45px;

            .line {
                width: 89.5%;
                position: absolute;
                height: 1px;
                background-color: #0b1c2d;
                &.line-1 {
                    top: 13px;
                }
                &.line-2 {
                    top: 16.5px;
                }
                &.line-3 {
                    top: 20px;
                }
            }

            .media-title-container {
                width: 50000px;
                display: flex;
                height: 100%;
                flex-wrap: wrap;
                align-content: center;
                
                .media-title-text {
                    font-size: 22px;
                    overflow: auto;
                    position: relative;
                    padding-right: 30px;
                    font-family: 'edit-undo';
                    color: #ED3636;

                    &#media-title-text-1 {
                        order: 0;
                    }
                    &#media-title-text-2 {
                        order: 1;
                    }
                    &#media-title-text-3 {
                        order: 2;
                    }
                    &#media-title-text-4 {
                        order: 3;
                    }
                }
            }
        }
    }
}